(function ($) {
  var basicImageV1 = {
    formatter: $('.basic-image-v1')
  };

  Drupal.behaviors.basicImageV1 = {
    get: function (key) {
      return basicImageV1[key];
    },

    set: function (key, val) {
      basicImageV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);
        var hotlistFormatter = thisFormatter.closest('.hotlists-nav-formatter-v1');
        var hotlistNav = hotlistFormatter.find('.hotlists-nav');
        var hotlists = hotlistFormatter.find('.hotlist');

        hotlists.each(function (i) {
          var id = $(this).attr('id');

          $(this).attr('item', 'hotlist-' + i);
          hotlistNav.find('a').attr('item', 'item="hotlist-' + i);
        });

        var topPositionNavOffset = $('#sticky-bar').height() - 5;

        thisFormatter.find('.link-tab').once('link-tab').on('click', function(e) {
          e.preventDefault();
          var thisId = $(this).attr('href');

          hotlistNav.find('.hotlist-tab').removeClass('active');
          hotlistFormatter.find('.hotlist').removeClass('active');
          var item = $(this).attr('item');
          var hotListHeadingColor = hotlistFormatter.find(thisId).find('.hotlist-heading').find('h3, h3 *[style]').filter(':last').css('color');

          hotlistFormatter.find(thisId).addClass('active');
          hotlistNav.find('a').not('.active').css({ background: '' });
          hotlistNav.find('a[href="' + thisId + '"]').addClass('active');
          hotlistNav.find('a[href="' + thisId + '"].active').css({ 'background': hotListHeadingColor });
          var clickedTabOffset = hotlistFormatter.find(thisId).offset();
          var clickedTabY = clickedTabOffset.top - $(window).scrollTop();

          $('body, html').scrollTo((clickedTabY - topPositionNavOffset) + $(window).scrollTop(), {
            duration: 500,
            axis: 'y'
          });
        });

        // Get the popup div element
        var popup = thisFormatter.find('.popup');

        // Miscellaneous css tweaks to the popup overlay
        popup
          .css({
            'position': 'absolute',
            'top': '0',
            'width': '500px',
            'background': '#eeeeee',
            'border': '1px solid #ccc',
            'opacity': '1',
            'z-index': '99999'
          });
        popup
          .find('.close')
          .css({
            'cursor': 'pointer',
            'margin': '5px 10px 0',
            'text-transform': 'uppercase',
            'text-indent': '-10000px',
            'background': 'url("/sites/clinique/themes/cl_base/img/icon-close.png") no-repeat 0 0',
            'width': '20px',
            'height': '20px',
            'position': 'absolute',
            'right': '0',
            'z-index': '99999'
          });

        var overlay = $('<div class="popup-overlay"></div>');

        overlay.css({
          'position': 'fixed',
          'top': '0',
          'left': '0',
          'width': '100%',
          'height': '100%',
          'opacity': '.6',
          'z-index': '99998',
          'margin-left': '20px'
        });

        // Open popup overlay by removing the hidden class from the .popup div.
        thisFormatter.find('.popup-link').once('popup-link').on('click', function(e) {
          e.preventDefault();
          overlay.insertBefore(popup);
          overlay.on('click', function() {
            popup.find('.close').trigger('click');
          });
          popup.removeClass('hidden');
          // CX-8780 Flex Slider resize after popup opened
          var $promoContainer = popup.find('.full-width-promo-v1 .flexslider');

          if ($promoContainer.length > 0) {
            $promoContainer.resize();
          }
        });

        // Popup close [x] button
        popup.find('.close').once('close').on('click', function(e) {
          e.preventDefault();
          popup.addClass('hidden');
          overlay.remove();
        });
      });
    }
  };
})(jQuery);
